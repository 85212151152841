import './App.css';
import { Feed } from './Feed';
import { Footer } from './Footer';
import { Header } from './Header';

export const App = () => {
  return (
    <div className="App">
      <Header />
      <div style={{ display: "flex", flexDirection: "row-reverse", flexWrap: "wrap", paddingLeft: "20px", paddingRight: "20px" }}>
        <div style={{ marginInlineStart: "10px", flex: 2, display: "flex", flexDirection: "column", marginBottom: "10px" }}>
          <h3 style={{ margin: 0, marginBottom: "10px" }}>About</h3>
          <span className='about-text'>
            <p>I'm a software engineer at Microsoft in Seattle working on Azure and Windows. It's been a great opportunity to gain experience building a cloud service at-scale and also developing low-level components in Windows. Apple fanboy turned Windows dev: who woulda thought? I'm also a volunteer TA for an AP Computer Science class through <a href="https://tealsk12.org">TEALS</a>.</p>
            <p>In 2021, I graduated from <a href="https://www.bu.edu">Boston University</a> with a degree in Computer Science. At school, I worked on a research project to develop a therapeutic game for children with ASD using the <a href="https://sphero.com">Sphero robotic ball</a> and volunteered with Global App Initiative, a student club building an app to help people navigate the U.S. immigration process. I also did an internship at Microsoft in 2020 where I worked on a new Teams feature.</p>
            <p>For three summers in high school and college, I interned at a <a href="https://embodied.com">robotics startup</a> where I added face tracking to a robot, built a data pipeline and web portal and wrote utility apps.</p>
            <p>Before that, I played around with iOS app development and web development (the first language I really got the hang of was PHP, strangely enough).</p>
            <p>I've been into computers all my life, and what was once only my hobby is now my career. I'm so lucky.</p>
          </span>
        </div>
        <Feed />
      </div>
      <Footer />
    </div>
  );
}

import './App.css';
import React from 'react';
import Gradient from 'rgt';

export const Header = () => {
    return (
        <div style={{textAlign: "center"}}>
            <h1 className="clickable" onClick={() => {window.location.assign("/")}}>
                {/* <Gradient dir="left-to-right" from="#f76ce9" to="#f76ca4">
                    Jake Kaplow
                </Gradient> */}
                <Gradient dir="left-to-right" from="#f4630e" to="#f40e2d">
                    Jake Kaplow
                </Gradient>
            </h1>
        </div>
    );
};
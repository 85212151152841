import './App.css';
import React from 'react';

export const Post = ({ name, username, text, date }) => {
    return (
        <div className='profile-box invisible-scrollbar'>
            <div className='profile-content'>
                <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                    <img src='/jake.jpeg' alt='jake kaplow' width={50} height={50} style={{ borderRadius: "50%" }} draggable={false} />
                    <div style={{ display: "flex", flexDirection: "column", marginLeft: "10px", textAlign: "left" }}>
                        <p style={{ fontSize: "16px" }}>{name}</p>
                        <p style={{ fontSize: "12px" }}>{username}</p>
                    </div>
                </div>
                <div style={{ display: "flex", textAlign: "start", flexWrap: "wrap" }}>
                    {text}
                </div>
            </div>
            <div style={{ alignSelf: "flex-end", justifyContent: "flex-start", alignItems: "center", display: "flex", flexDirection: "row-reverse", padding: "0px", marginTop: "5px" }}>
                <span className='metadata'><p>{date}</p></span>
                <span className='metadata'><span class="material-symbols-outlined">public</span></span>
            </div>
        </div>
    );
};
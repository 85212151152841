import './App.css';
import React from 'react';
import { Post } from './Post';

export const Feed = () => {
    return (
        <div className='feed-box'>
            <h3 style={{ margin: 0, marginBottom: "10px" }}>Feed</h3>
            <Post
                name={"Jake Kaplow"}
                username={"@jakekaplow"}
                date={"11/25/2022 3:34pm"}
                text={<p>see my linkedin <a href="https://linkedin.com/in/jakekaplow">linkedin.com/in/jakekaplow</a></p>} />
            <Post
                name={"Jake Kaplow"}
                username={"@jakekaplow"}
                date={"11/25/2022 12:27pm"}
                text={<p>everyone's making their own Tw*tter these days</p>} />
            <Post
                name={"Jake Kaplow"}
                username={"@jakekaplow"}
                date={"05/03/2008 10:48am"}
                text={<><p>new email:</p><img src="/email.png" width={200} style={{marginLeft: "5px"}} draggable={false} alt={"email address: jkaplow at me"} /></>} />
        </div>
    );
};